import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54')
];

export const server_loads = [0,4];

export const dictionary = {
		"/(main)": [~41,[4],[5]],
		"/(main)/blog": [~42,[4],[5]],
		"/(main)/blog/[post]/[[title]]": [~43,[4],[5]],
		"/(main)/category/[id]/[[name]]": [~44,[4],[5]],
		"/(dashboard)/dashboard": [6,[2,3]],
		"/(dashboard)/dashboard/admin": [~7,[2,3]],
		"/(dashboard)/dashboard/admin/categories": [8,[2,3]],
		"/(dashboard)/dashboard/admin/clear-accounts": [~9,[2,3]],
		"/(dashboard)/dashboard/admin/emails": [10,[2,3]],
		"/(dashboard)/dashboard/admin/init-custom-flavors": [~11,[2,3]],
		"/(dashboard)/dashboard/admin/inquiries": [12,[2,3]],
		"/(dashboard)/dashboard/admin/playground": [~13,[2,3]],
		"/(dashboard)/dashboard/admin/reflect": [~14,[2,3]],
		"/(dashboard)/dashboard/admin/refresh-config": [~15,[2,3]],
		"/(dashboard)/dashboard/admin/spreadsheet": [~16,[2,3]],
		"/(dashboard)/dashboard/admin/telegram": [17,[2,3]],
		"/(dashboard)/dashboard/analytics/requested-flavors": [~18,[2,3]],
		"/(dashboard)/dashboard/analytics/search": [~19,[2,3]],
		"/(dashboard)/dashboard/blog": [~20,[2,3]],
		"/(dashboard)/dashboard/blog/post/[postid]": [~21,[2,3]],
		"/(dashboard)/dashboard/inquiries": [~22,[2,3]],
		"/(dashboard)/dashboard/inquiries/[...id]": [~23,[2,3]],
		"/(dashboard)/dashboard/inquiry-stats": [~24,[2,3]],
		"/(dashboard)/dashboard/newsletter": [~25,[2,3]],
		"/(dashboard)/dashboard/not-allowed": [26,[2,3]],
		"/(dashboard)/dashboard/orders": [~27,[2,3]],
		"/(dashboard)/dashboard/orders/[...id]": [~28,[2,3]],
		"/(dashboard)/dashboard/partners": [~29,[2,3]],
		"/(dashboard)/dashboard/prices": [~30,[2,3]],
		"/(dashboard)/dashboard/products": [~31,[2,3]],
		"/(dashboard)/dashboard/products/[...id]": [~32,[2,3]],
		"/(dashboard)/dashboard/resources": [~33,[2,3]],
		"/(dashboard)/dashboard/sale-reps": [~34,[2,3]],
		"/(dashboard)/dashboard/tenders": [~35,[2,3]],
		"/(dashboard)/dashboard/tenders/[...tenderId]": [~36,[2,3]],
		"/(main)/inquiry": [45,[4],[5]],
		"/(main)/inquiry/confirmation/[...inquiryId]": [~46,[4],[5]],
		"/(main)/legal/privacy-policy": [47,[4],[5]],
		"/(links)/links/invoice/[...id]": [~39],
		"/(links)/links/tender/[code]": [~40],
		"/(main)/login2/[...expected]": [~48,[4],[5]],
		"/(main)/order/confirmation/[...orderId]": [~50,[4],[5]],
		"/(main)/order/[...inquiryId]": [~49,[4],[5]],
		"/(main)/product/[id]/[[name]]": [~51,[4],[5]],
		"/(main)/register/thank-you": [52,[4],[5]],
		"/(main)/search": [53,[4],[5]],
		"/(main)/ui2": [54,[4],[5]],
		"/(dashboard)/ui": [~37,[2]],
		"/(dashboard)/ui/dp": [38,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';